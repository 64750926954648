<template>
	<div>
		<b-card no-body>
			<b-card title="" header-tag="header">
				<template v-slot:header>
					<product-form-nav link="link" :productDetails="productDetails" :productId="id"></product-form-nav>
				</template>
				<form class="form" novalidate="novalidate" id="product_link">
					<b-card-text class="v-application">
						<label>Category</label>
						<b-row class="mb-4">
							<b-col cols="8">
								<treeselect v-model="category" :multiple="true" :flat="true" :max-height="200" 
									:default-expand-level="1" :options="options" />
							</b-col>
						</b-row>
						<label>Manufacturer</label>
						<b-row class="mb-4">
							<b-col cols="4">
								<b-form-select v-model="manufacture" :options="manufactures"></b-form-select>
							</b-col>
						</b-row>
						<b-row v-if="productDetails.product_type == 'frame' && productDetails.prescription_status == 1">
							<b-col lg="3" md="6">
								<b-form-group>
									<label>Price Group</label>
									<b-form-select v-model="selectedPriceGroup" :options="packageGroups"></b-form-select>
								</b-form-group>
							</b-col>
							
						</b-row>
						<b-row>
							<b-col class="text-right">
								<button class="btn btn-primary" ref="submit_link">
									<i class="las la-save"></i> Save and Continue
								</button>
							</b-col>
						</b-row>
					</b-card-text>
				</form>
			</b-card>
		</b-card>
	</div>
</template>

<script>
	import ProductFormNav from "./components/ProductFormNav.vue";
	import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

	// FormValidation plugins
	import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
	import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
	import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
	import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
	import KTUtil from "@/assets/js/components/util";

	//api services
	import ProductService from "@/core/services/api/product/product";
	import ManufactureService from "@/core/services/api/product/manufacture";
	import CategoryService from "@/core/services/api/category";
	import LensPackageService from "@/core/services/api/lensPackage";
	export default {
		components: {
			ProductFormNav,
		},
		data() {
			return {
				id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
				manufactures: [],
				manufacture:0,
				selectedPriceGroup:null,
				category: [],
				form:{},
				options: [],
				productDetails:{},
				packageGroups:[],
			};
		},
		created (){
			this.getProductById();
			this.getManufactureList();
			this.getCategoryTreeList();
			this.getPriceGroups();
		},
		mounted() {
			this.$store.dispatch(SET_BREADCRUMB, [
				{
					title: "Dashboard",
					route: "/dashboard",
				},
				{
					title: "Products",
					route: "/products",
				},
				{
					title: "Manage Product : Link",
				},
			]);
			
			const signin_form = KTUtil.getById("product_link");
			this.fv = formValidation(signin_form, {
				fields: {
					
				},
				plugins: {
					trigger: new Trigger(),
					submitButton: new SubmitButton(),
					bootstrap: new Bootstrap(),
				},
			});
			this.fv.on("core.form.valid", () => {
				// set spinner to submit button
				const submitButton = this.$refs["submit_link"];
				submitButton.classList.add("spinner", "spinner-light", "spinner-right","disabled");
				this.updateProduct(function(){
					submitButton.classList.remove(
						"spinner",
						"spinner-light",
						"spinner-right",
						"disabled"
					);
				});
			});
				
		},
		methods: {
			async getPriceGroups() {
				var response = await LensPackageService.getLensPriceGroups();
				this.packageGroups.push ({
					value:null,
					text:"Please select a price group"
				});
				response.map((e)=>{
					this.packageGroups.push ({
						value:e.id,
						text:e.name
					})
				});
			},
			async getManufactureList() {
				var response = await ManufactureService.getManufactureList();
				 this.manufactures.push ({
					value:0,
					text:"Please select a manufacture"
				});
				response.map((e)=>{
					this.manufactures.push ({
						value:e.id,
						text:e.name
					})
				});
			},
			tree(e){
				var children = [];
				e.map((child)=>{
					children.push({
						id:child.id,
						label:child.name,
						children:child.children?this.tree(child.children):[]
					})
				})
				return children;
			},
			async getCategoryTreeList() {
				var response = await CategoryService.getCategoryTree();
				response.map((e)=>{
					this.options.push({
						id:e.id,
						label:e.name,
						children:e.children?this.tree(e.children):[]
					})
				});
			},
			async getProductById() {
				var vm = this;
				var response = await ProductService.getProductById(this.id);
				vm.productDetails = response;
				vm.manufacture = vm.productDetails.manufacture;
				vm.selectedPriceGroup = vm.productDetails.lensePackageGroupID;
				vm.productDetails.category.map((e)=>{
					vm.category.push(e.category_id)
				})
			},
			updateProduct(callback) {
				var vm = this;
				var form = {
					manufacture: vm.manufacture,
					category: vm.category,
					lensePackageGroupID : vm.selectedPriceGroup
				}
				ProductService.updateProduct(vm.id,form).then(function (response) {
						vm.$router.push({ name: "product-form-filter", param: { id: vm.id } })
				}).catch(function (error){
					callback();
				});
			},
		},
	};
</script>

<style></style>